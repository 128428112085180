var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MasterDetail',{attrs:{"data-test-id":"userPage","detail-open":!!_vm.selectedUser,"loading":_vm.runningAction},on:{"table:resize":function($event){_vm.tableWidth = $event}},scopedSlots:_vm._u([{key:"toolbar",fn:function(ref){
var loading = ref.loading;
return [_c('Toolbar',{staticClass:"user-table-toolbar",attrs:{"search-props":{
        disableFullTextSearch: true,
        possibleFilters: _vm.possibleFilters,
      }},on:{"update-filters":function (updated) { return (_vm.filters = updated); }},scopedSlots:_vm._u([{key:"extended",fn:function(){return [_c('div',{staticClass:"extended-toolbar"},[_c('v-select',{staticClass:"user-limit-select",attrs:{"dense":"","hide-details":"","items":_vm.pageLimits,"disabled":loading,"data-test-id":"pageLimitSelector"},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}}),_vm._v(" rows "),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('PaginationComponent',{attrs:{"disabled":loading,"disable-next-btn":!_vm.hasMorePages,"hide-page-input":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-spacer'),_c('v-switch',{staticClass:"mt-0 ml-3",attrs:{"data-test-id":"showDisabledSwitch","label":"Show disabled","color":"primary","hide-details":"","disabled":loading},on:{"change":_vm.loadUsers},model:{value:(_vm.showDisabled),callback:function ($$v) {_vm.showDisabled=$$v},expression:"showDisabled"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","data-test-id":"newUserBtn","title":"Create a new keycloak user","fab":_vm.showMinified,"small":_vm.showMinified,"disabled":loading || _vm.isModeCreate},on:{"click":function($event){return _vm.createUser()}}},[(_vm.showMinified)?_c('v-icon',[_vm._v("mdi-plus")]):_c('div',[_vm._v("New User")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.filterQuery),callback:function ($$v) {_vm.filterQuery=$$v},expression:"filterQuery"}})]}},{key:"table",fn:function(ref){
      var loading = ref.loading;
return [_c('v-data-table',{staticClass:"user-table",attrs:{"dense":"","disable-pagination":"","hide-default-footer":"","fixed-header":"","single-select":"","multi-sort":"","data-test-id":"userTable","items":_vm.users,"headers":_vm.headers,"loading":loading,"height":_vm.tableHeight,"item-class":_vm.getItemClass},on:{"click:row":_vm.openUserDetail},scopedSlots:_vm._u([{key:"item.creationDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$getLocalizedDate(item.creationDate))+" ")]}},{key:"item.disabled",fn:function(ref){
      var item = ref.item;
return [(!item.enabled)?_c('v-icon',{attrs:{"disabled":"","data-test-id":'disabledIcon_' + item.username}},[_vm._v(" mdi-close-circle-outline ")]):_vm._e()]}}],null,true)})]}},{key:"detail",fn:function(){return [(!!_vm.selectedUser)?_c('UserDetail',{key:_vm.selectedUser.id,style:({
        height: _vm.detailHeight + 'px',
      }),attrs:{"preview-data":_vm.selectedUser,"roles":_vm.roles},on:{"close":_vm.closeDetail,"reload":_vm.reloadUsers,"deleted":function($event){return _vm.closeDetail(true)}}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }