var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-group-overview"},[_c('DataTable',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.groups},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var loading = ref.loading;
return [_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","data-test-id":"addGroupBtn","disabled":_vm.disabled || loading},on:{"click":function($event){_vm.addGroup = true}}},[_vm._v(" Add Group ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
var loading = ref.loading;
return [_c('div',{staticClass:"d-flex flex-grow-1 align-center justify-start"},[_c('div',[_c('a',{attrs:{"href":_vm.getGroupLink(_vm.selectedDomain, item.id),"data-test-id":'userGroupName_' + item.name},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openGroupDetail(_vm.selectedDomain, item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var loading = ref.loading;
return [_c('v-btn',{attrs:{"small":"","icon":"","color":"red","disabled":_vm.disabled || loading,"data-test-id":'removeGroupBtn_' + item.name},on:{"click":function($event){return _vm.removeGroup(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.addGroup),callback:function ($$v) {_vm.addGroup=$$v},expression:"addGroup"}},[(_vm.addGroup)?_c('UserGroup',{attrs:{"user-id":_vm.userId},on:{"close":_vm.closeDialog}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }