<template>
  <DialogForm
    class="new-user-group-form"
    data-test-id="userGroup"
    title="Add user to group"
    save-btn-label="Add"
    close-btn-label="Cancel"
    :loading="runningAction"
    @close="$emit('close', false)"
    @save="addGroup"
  >
    <template #form="{ loading }">
      <v-form @submit.prevent ref="groupForm">
        <v-container fluid class="pa-0">
          <v-row>
            <v-col>
              <GroupSelector
                v-model="group"
                :disabled="loading || disabled"
                :rules="[ruleSet.required]"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </DialogForm>
</template>


<script>
import DialogForm from "components/common/templates/DialogForm";
import GroupSelector from "components/user-management/group/GroupSelector";
import validationMixin from "mixins/field-rule-validation";

export default {
  mixins: [validationMixin],
  components: {
    GroupSelector,
    DialogForm,
  },

  props: {
    userId: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      runningAction: false,
      group: {},
    };
  },

  methods: {
    async addGroup() {
      try {
        this.runningAction = true;
        const form = this.$refs.groupForm;
        if (this.$validateVForm(form)) {
          const name = this.group?.name;
          const successMsg =
            `Added user to group <strong>` + name + `</strong>`;
          const response =
            await this.$store.$coreApi.coreUserApi.addUserToGroup(
              this.selectedDomain,
              this.userId,
              this.group.id,
              { successMsg }
            );

          if (response.ok) {
            this.$emit("close", true);
          }
        }
      } finally {
        this.runningAction = false;
      }
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },
  },
};
</script>

<style scoped>
</style>