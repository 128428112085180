<template>
  <div class="group-permission-overview">
    <DataTable
      title="Permissions"
      class="permission-table"
      :items="directPermissions"
      :headers="permissionHeaders"
      :loading="loadingDirectPermissions"
      :limit="limit"
    >
      <template #actions="{ loading }">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          data-test-id="addPermissionBtn"
          :disabled="disabled || loading"
          @click="addPermission = true"
        >
          Add Permission
        </v-btn>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.actions="{ item, loading }">
        <v-btn
          small
          icon
          color="red"
          :disabled="disabled || loading"
          :data-test-id="'revokePermissionBtn_' + item.resourceDomain"
          @click="revokePermission(item)"
        >
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
    </DataTable>

    <DataTable
      title="Permissions from groups"
      class="inherited-permission-table"
      :items="groupPermissions"
      :headers="inheritedPermissionHeaders"
      :loading="loadingGroupPermissions"
      :limit="limit"
    >
      <!-- eslint-disable-next-line -->
      <template #item.group="{ item, loading }">
        <a
          :href="getGroupLink(item.group.domainId, item.group.id)"
          :data-test-id="'permissionAncestorName_' + item.group.name"
          @click.stop.prevent="
            openGroupDetail(item.group.domainId, item.group.id)
          "
        >
          {{ item.group.name }}
        </a>
      </template>
    </DataTable>

    <v-dialog v-model="addPermission" persistent max-width="500">
      <Permission :entity-id="userId" @close="closeDetail" />
    </v-dialog>
  </div>
</template>

<script>
import DataTable from "components/common/templates/DataTable";
import Permission from "components/user-management/permission/Permission";
import groupMixin from "mixins/group-mixin";
export default {
  mixins: [groupMixin],
  components: {
    DataTable,
    Permission,
  },

  props: {
    userId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      directPermissions: [],
      groupPermissions: [],
      loadingDirectPermissions: false,
      loadingGroupPermissions: false,
      addPermission: false,
    };
  },

  async mounted() {
    await this.$nextTick();
    await Promise.allSettled([
      this.loadPermissions(),
      this.loadGroupPermissions(),
    ]);
  },

  methods: {
    async loadPermissions() {
      try {
        this.loadingDirectPermissions = true;
        const offset = 0;
        const response =
          await this.$store.$coreApi.coreUserApi.getUserPermissions(
            this.selectedDomain,
            this.userId,
            this.limit,
            offset
          );

        const permissions = response?.permissions ?? [];
        this.directPermissions = permissions;
      } finally {
        this.loadingDirectPermissions = false;
      }
    },

    async loadGroupPermissions() {
      try {
        this.loadingGroupPermissions = true;
        const response = await this.$store.$coreApi.coreUserApi.getUserGroups(
          this.selectedDomain,
          this.userId,
          this.limit,
          0
        );

        const groups = response?.groups ?? [];
        let allGroupPermissions = [];
        for (const group of groups) {
          const permissionsResponse = await this.getGroupPermissions(
            group.id,
            this.limit
          );
          const permissions = (permissionsResponse.permissions ?? []).map(
            (permission) => {
              return {
                ...permission,
                group,
              };
            }
          );

          const ancestorPermissions = await this.loadAncestorPermissions(
            group.id
          );
          allGroupPermissions = allGroupPermissions
            .concat(permissions)
            .concat(ancestorPermissions);
        }
        this.groupPermissions = allGroupPermissions.reduce(
          (array, permission) => {
            const exists = array.some(({ type, resourceDomain, group }) => {
              return (
                group?.id === permission.ancestor?.id &&
                resourceDomain === permission.resourceDomain &&
                type === permission.type
              );
            });
            if (!exists) {
              const group = permission.group ?? permission.ancestor;
              array.push({
                group,
                type: permission.type,
                resourceDomain: permission.resourceDomain,
              });
            }
            return array;
          },
          []
        );
      } finally {
        this.loadingGroupPermissions = false;
      }
    },

    async revokePermission(permission) {
      try {
        const type = permission?.type;
        const resourceDomain = permission?.resourceDomain;

        const confirmed = await this.$confirm(
          "Revoke permission?",
          "Are you sure you want to revoke permission <strong>" +
            type +
            "</strong> for domain <strong>" +
            resourceDomain +
            "</strong>?"
        );

        if (!confirmed) return;
        this.loadingDirectPermissions = true;
        const successMsg =
          "<strong>Revoked</strong> permission <strong>" +
          type +
          "</strong> for domain <strong>" +
          resourceDomain +
          "</strong>";
        const response =
          await this.$store.$coreApi.coreUserApi.revokeUserPermission(
            this.selectedDomain,
            this.userId,
            type,
            resourceDomain,
            { successMsg }
          );

        if (response?.ok) {
          await this.loadPermissions();
        }
      } finally {
        this.loadingDirectPermissions = false;
      }
    },

    closeDetail(reload) {
      this.addPermission = false;
      if (reload) {
        this.loadPermissions();
      }
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    limit() {
      //MAX Java Integer Value
      return Math.pow(2, 31) - 1;
    },

    permissionHeaders() {
      return [
        { text: "Type", value: "type", sortable: false },
        { text: "Domain", value: "resourceDomain", sortable: false },
        { text: "", value: "actions", sortable: false, width: "128px" },
      ];
    },

    inheritedPermissionHeaders() {
      return [
        { text: "From group", value: "group", sortable: false },
        { text: "Type", value: "type", sortable: false },
        { text: "Domain", value: "resourceDomain", sortable: false },
      ];
    },
  },
};
</script>

<style scoped>
.group-permission-overview {
  display: flex;
  flex-direction: column;
}

.group-permission-overview > .permission-table,
.group-permission-overview > .inherited-permission-table {
  padding: 12px;
}
</style>