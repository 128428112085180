<template>
  <div class="user-group-overview">
    <DataTable dense :headers="headers" :items="groups">
      <template #actions="{ loading }">
        <v-spacer />
        <v-btn
          color="primary"
          data-test-id="addGroupBtn"
          :disabled="disabled || loading"
          @click="addGroup = true"
        >
          Add Group
        </v-btn>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.name="{ item, loading }">
        <div class="d-flex flex-grow-1 align-center justify-start">
          <div>
            <a
              :href="getGroupLink(selectedDomain, item.id)"
              :data-test-id="'userGroupName_' + item.name"
              @click.stop.prevent="openGroupDetail(selectedDomain, item.id)"
            >
              {{ item.name }}
            </a>
          </div>
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.actions="{ item, loading }">
        <v-btn
          small
          icon
          color="red"
          :disabled="disabled || loading"
          :data-test-id="'removeGroupBtn_' + item.name"
          @click="removeGroup(item)"
        >
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
    </DataTable>
    <v-dialog v-model="addGroup" persistent max-width="500">
      <UserGroup v-if="addGroup" :user-id="userId" @close="closeDialog" />
    </v-dialog>
  </div>
</template>
<script>
import DataTable from "components/common/templates/DataTable";
import UserGroup from "./UserGroup";
import groupMixin from "mixins/group-mixin.js";
export default {
  mixins: [groupMixin],
  components: {
    UserGroup,
    DataTable,
  },

  props: {
    userId: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      groups: [],
      runningAction: true,
      addGroup: false,
    };
  },

  mounted() {
    this.loadGroups();
  },

  methods: {
    async loadGroups() {
      try {
        this.runningAction = true;
        const offset = 0;
        const response = await this.$store.$coreApi.coreUserApi.getUserGroups(
          this.selectedDomain,
          this.userId,
          this.limit,
          offset
        );

        this.groups = response?.groups ?? [];
      } finally {
        this.runningAction = false;
      }
    },

    async removeGroup({ id, name }) {
      try {
        const confirmed = await this.$confirm(
          "Remove user from group?",
          "Are you sure you want to remove the user from group <strong>" +
            name +
            "</strong>?"
        );

        if (!confirmed) return;

        this.runningAction = true;
        const successMsg =
          `Removed user from group <strong>` + name + `</strong>`;
        const response =
          await this.$store.$coreApi.coreUserApi.removeUserFromGroup(
            this.selectedDomain,
            this.userId,
            id,
            {
              successMsg,
            }
          );

        if (response?.ok) {
          await this.loadGroups();
        }
      } finally {
        this.runningAction = false;
      }
    },

    closeDialog(reload) {
      this.addGroup = false;
      if (reload) this.loadGroups();
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    limit() {
      return Math.pow(2, 31) - 1;
    },

    headers() {
      return [
        { text: "Name", value: "name" },
        { text: "", value: "actions", sortable: false, width: "128px" },
      ];
    },
  },
};
</script>
<style scoped>
.user-group-overview {
  display: flex;
  flex-grow: 1;
  padding: 12px;
  flex-grow: 1;
  align-items: flex-start;
}
</style>

