<template>
  <DetailView
    data-test-id="userDetail"
    class="user-detail"
    :loading="runningAction"
    :title="title"
    :tabs="tabs"
    @close="$emit('close')"
  >
    <template #actions="{ loading }">
      <v-btn
        v-if="isCreate"
        :color="hasViolation ? 'error' : 'green'"
        data-test-id="userSaveBtn"
        :disabled="loading"
        @click="upsertUser"
      >
        <ViolationAlert
          v-if="hasViolation"
          :violation="violation"
          :color="hasChanged || isCreate ? 'white' : 'error'"
          data-test-id="userViolationAlert"
          alignment="left"
        />
        Create
      </v-btn>
      <div v-else />
    </template>

    <!-- eslint-disable-next-line -->
    <template #tab.overview="{ loading }">
      <v-form
        @submit.prevent
        ref="userForm"
        class="user-form"
        data-test-id="userForm"
      >
        <div>
          <v-toolbar flat>
            <v-toolbar-title>User Information</v-toolbar-title>
            <v-spacer />
            <div v-if="!isCreate" class="user-information-actions">
              <v-btn
                outlined
                data-test-id="userKeycloakBtn"
                :disabled="loading"
                @click="openUserInKeycloak(user.id)"
              >
                <v-icon left>mdi-open-in-new</v-icon>
                Open in Keycloak
              </v-btn>
              <v-btn
                :color="hasViolation ? 'error' : 'green'"
                data-test-id="userSaveBtn"
                :outlined="!hasChanged"
                :disabled="loading"
                @click="upsertUser"
              >
                <ViolationAlert
                  v-if="hasViolation"
                  :violation="violation"
                  :color="hasChanged || isCreate ? 'white' : 'error'"
                  data-test-id="userViolationAlert"
                  alignment="left"
                />
                Save
              </v-btn>
              <v-btn
                color="red"
                outlined
                data-test-id="userDeleteBtn"
                :disabled="loading"
                @click="deleteUser"
              >
                Delete
              </v-btn>
            </div>
          </v-toolbar>

          <v-container fluid>
            <v-row v-if="!isCreate">
              <v-col>
                <v-text-field
                  v-model="user.id"
                  dense
                  outlined
                  disabled
                  readonly
                  label="ID*"
                  data-test-id="userId"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.username"
                  dense
                  outlined
                  data-test-id="userUserName"
                  label="Username*"
                  hide-details="auto"
                  :readonly="!isCreate"
                  :disabled="loading || !isCreate"
                  :rules="[
                    ruleSet.required,
                    ruleSet.minLength(4),
                    ruleSet.maxLength(64),
                  ]"
                />
              </v-col>
              <v-col cols="2">
                <!-- TODO: Check why enabled cannot be set -->
                <v-checkbox
                  dense
                  label="Enabled"
                  class="mt-1"
                  data-test-id="userEnabled"
                  hide-details="auto"
                  :input-value="!!user.enabled"
                  :disabled="loading"
                  @change="user.enabled = !user.enabled"
                />
              </v-col>
            </v-row>
            <v-row v-if="isCreate">
              <v-col>
                <PasswordField
                  v-model="password"
                  label="Password"
                  data-test-id="userPassword"
                  :rules="[ruleSet.required]"
                  :disabled="loading"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.email"
                  dense
                  outlined
                  label="Email"
                  hide-details="auto"
                  data-test-id="userEmail"
                  :rules="[ruleSet.email]"
                  :disabled="loading"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.firstname"
                  dense
                  outlined
                  label="First Name"
                  hide-details="auto"
                  data-test-id="userFirstName"
                  :disabled="loading"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="user.lastname"
                  dense
                  outlined
                  label="Last Name"
                  data-test-id="userLastName"
                  hide-details="auto"
                  :disabled="loading"
                />
              </v-col>
            </v-row>
          </v-container>
        </div>

        <div v-if="!isCreate" flat>
          <v-toolbar flat>
            <v-toolbar-title>Change password</v-toolbar-title>
            <v-divider vertical class="mx-3" />
            <div
              v-if="passwordCreateDate"
              class="d-flex flex-row align-center text-caption"
            >
              <v-icon small>mdi-clock-outline</v-icon>
              <div class="font-weight-medium mr-2 ml-1">Last update:</div>
              <div>{{ $getLocalizedDate(passwordCreateDate) }}</div>
            </div>
            <v-spacer />
            <v-btn
              color="green"
              data-test-id="userPasswordResetBtn"
              :disabled="loading || !password"
              @click="updateCredentials"
            >
              Update
            </v-btn>
          </v-toolbar>
          <PasswordField
            v-model="password"
            label="New Password"
            data-test-id="userNewPassword"
            class="ma-3"
            :disabled="loading"
          />
        </div>

        <div>
          <v-toolbar flat>
            <v-toolbar-title> Role Management </v-toolbar-title>
          </v-toolbar>
          <UserRoleEditor
            data-test-id="userRoleList"
            :roles="roles"
            :user="user"
            :disabled="loading"
            @input="initialRoles = $event"
          />
        </div>
      </v-form>
    </template>

    <!-- eslint-disable-next-line -->
    <template #tab.permissions="{ loading }">
      <UserPermissionOverview :user-id="userId" />
    </template>

    <!-- eslint-disable-next-line -->
    <template #tab.groups="{ loading }">
      <UserGroupOverview :user-id="userId" />
    </template>
  </DetailView>
</template>

<script>
import DetailView from "components/common/templates/DetailView";
import ViolationAlert from "components/common/display-helpers/ViolationAlert";
import UserRoleEditor from "./role/UserRoleEditor";
import UserPermissionOverview from "./permission/UserPermissionOverview";
import UserGroupOverview from "./group/UserGroupOverview";
import PasswordField from "components/common/inputs/PasswordField";
import validationMixin from "mixins/field-rule-validation";
import keycloakMixin from "mixins/keycloak-mixin";

export default {
  mixins: [validationMixin, keycloakMixin],
  components: {
    DetailView,
    UserRoleEditor,
    UserPermissionOverview,
    UserGroupOverview,
    ViolationAlert,
    PasswordField,
  },

  props: {
    previewData: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },

    roles: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      user: this.$cloneObject(this.previewData),
      savedUser: this.previewData,
      runningAction: false,
      hasChanged: false,
      password: null,
      initialRoles: [],
      violation: null,
      passwordCreateDate: null,
    };
  },

  mounted() {
    if (!this.isCreate) {
      this.loadUser();
    } else {
      this.$set(this.user, "enabled", true);
      this.savedUser = this.$cloneObject(this.user);
    }
  },

  watch: {
    "previewData.id": function (id) {
      if (id !== this.user.id) {
        this.user = this.$cloneObject(this.previewData);
        this.loadUser();
      }
    },

    user: {
      handler: function (user) {
        this.hasChanged = !this.$isEqual(user, this.savedUser);
      },
      deep: true,
    },

    savedUser: {
      handler: function (savedUser) {
        this.hasChanged = !this.$isEqual(this.user, savedUser);
      },
      deep: true,
    },

    hasChanged(hasChanged) {
      this.$set(this.$route.params, "unsavedChanges", hasChanged);
    },
  },

  methods: {
    async loadUser() {
      try {
        this.runningAction = true;

        const user = await this.$store.$coreApi.coreUserApi.getUser(
          this.selectedDomain,
          this.user.id
        );

        this.user = user;
        this.savedUser = this.$cloneObject(user);
        await this.loadCredentials();
      } finally {
        this.runningAction = false;
      }
    },

    async loadCredentials() {
      try {
        this.runningAction = true;
        const response = await this.$store.$coreApi.coreUserApi.getCredentials(
          this.selectedDomain,
          this.user.id
        );

        const credentials = (response?.credentials ?? []).filter(
          (credentials) => credentials?.type === "password"
        );
        const passwordCredentials = credentials[0];
        this.passwordCreateDate = passwordCredentials?.creationDate;
      } finally {
        this.runningAction = false;
      }
    },

    async upsertUser() {
      try {
        if (!this.validate()) return;
        this.runningAction = true;
        this.violation = null;
        let response;
        if (this.isCreate) {
          const credentials = [
            {
              type: "password",
              value: this.password,
            },
          ];

          const body = {
            user: this.user,
            initialRoles: this.initialRoles,
            credentials,
          };

          response = await this.$store.$coreApi.coreUserApi.createUser(
            this.selectedDomain,
            body,
            {
              returnErrors: true,
              successMsg:
                "User <strong>" + this.user.username + "</strong> created",
            }
          );
        } else {
          response = await this.$store.$coreApi.coreUserApi.updateUser(
            this.selectedDomain,
            this.user,
            {
              successMsg:
                "User <strong>" + this.user.username + "</strong> updated",
              returnErrors: true,
            }
          );

          if (response?.ok) {
            this.savedUser = this.$cloneObject(this.user);
          }
        }

        if (!response?.ok) {
          //Error handling
          try {
            const error = await response.json();
            if (error.violations) {
              this.violation = error;
              return;
            }
            this.violation = {
              message: error.message,
            };
          } catch (error) {
            this.violation = {
              message: "An unexpected error occured",
            };
          }
          return;
        }

        if (this.isCreate) {
          this.user = await response.json();
          await this.loadUser();
          await this.$nextTick();
          await this.$router.replace({
            name: "userDetail",
            params: { user: this.user.id },
            query: this.$route.query,
          });
        }

        this.$emit("reload", this.user.id);
      } finally {
        this.runningAction = false;
      }
    },

    async deleteUser() {
      if (
        await this.$confirm(
          "Delete user?",
          "Are you sure you want to delete user " +
            this.user.username +
            "? This cannot be undone."
        )
      ) {
        this.runningAction = true;
        try {
          const response = await this.$store.$coreApi.coreUserApi.deleteUser(
            this.selectedDomain,
            this.user.id,
            {
              successMsg:
                "User <strong>" + this.user.username + "</strong> deleted",
            }
          );
          if (!response?.ok) return;
          this.$emit("deleted");
        } finally {
          this.runningAction = false;
        }
      }
    },

    async updateCredentials() {
      if (!this.password) return;
      try {
        this.runningAction = true;
        //Update password
        const username = this.user.username;
        const confirmed = await this.$confirm(
          "Change Password?",
          "Do you really want to change the password of user " + username + "?"
        );
        if (!confirmed) return;

        const body = {
          type: "password",
          value: this.password,
        };

        const response =
          await this.$store.$coreApi.coreUserApi.updateCredentials(
            this.selectedDomain,
            this.user.id,
            body,
            {
              successMsg:
                "Credentials of user <strong>" + username + "</strong> updated",
            }
          );
        if (!response?.ok) {
          this.$store.commit("SET_ERROR", "Update of credentials failed");
          return;
        }

        //Reset password field
        this.password = null;
        await this.loadCredentials();
      } finally {
        this.runningAction = false;
      }
    },

    validate() {
      const form = this.$refs.userForm;
      if (!this.$validateVForm(form)) {
        this.violation = {
          message: "At least one input is invalid, please check",
        };
        return false;
      }
      this.violation = null;
      return true;
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    isCreate() {
      return !this.previewData.id;
    },

    title() {
      return this.isCreate ? "New User" : this.user.username;
    },

    hasViolation() {
      return !!this.violation;
    },

    tabs() {
      const tabs = [
        { text: "Overview", value: "overview" },
        { text: "Permissions", value: "permissions" },
        { text: "Groups", value: "groups" },
      ];

      if (this.isCreate) {
        return tabs.filter(({ value }) => value === "overview");
      }
      return tabs;
    },

    userId() {
      return this.user.id;
    },
  },
};
</script>

<style scoped>
.user-detail .v-card + .v-card {
  margin-top: 12px;
  border-top: 1px solid lightgray;
}

.user-detail .user-information-actions {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.user-detail .user-information-actions > .v-btn + .v-btn {
  margin-left: 8px;
}
</style>