<template>
  <MasterDetail
    data-test-id="userPage"
    :detail-open="!!selectedUser"
    :loading="runningAction"
    @table:resize="tableWidth = $event"
  >
    <template #toolbar="{ loading }">
      <Toolbar
        v-model="filterQuery"
        class="user-table-toolbar"
        :search-props="{
          disableFullTextSearch: true,
          possibleFilters,
        }"
        @update-filters="(updated) => (filters = updated)"
      >
        <template #extended>
          <div class="extended-toolbar">
            <v-select
              class="user-limit-select"
              v-model="limit"
              dense
              hide-details
              :items="pageLimits"
              :disabled="loading"
              data-test-id="pageLimitSelector"
            />
            rows
            <v-divider class="mx-4" vertical></v-divider>

            <!-- CUSTOM PAGINATION COMPONENT -->
            <PaginationComponent
              v-model="currentPage"
              :disabled="loading"
              :disable-next-btn="!hasMorePages"
              hide-page-input
            />
            <v-divider class="mx-4" vertical></v-divider>
            <v-spacer></v-spacer>
            <v-switch
              data-test-id="showDisabledSwitch"
              v-model="showDisabled"
              label="Show disabled"
              color="primary"
              hide-details
              class="mt-0 ml-3"
              :disabled="loading"
              @change="loadUsers"
            />
            <v-divider class="mx-4" vertical />
            <v-btn
              color="primary"
              class="ml-3"
              data-test-id="newUserBtn"
              title="Create a new keycloak user"
              :fab="showMinified"
              :small="showMinified"
              :disabled="loading || isModeCreate"
              @click="createUser()"
            >
              <v-icon v-if="showMinified">mdi-plus</v-icon>
              <div v-else>New User</div>
            </v-btn>
          </div>
        </template>
      </Toolbar>
    </template>
    <template #table="{ loading }">
      <v-data-table
        dense
        disable-pagination
        hide-default-footer
        fixed-header
        single-select
        multi-sort
        class="user-table"
        data-test-id="userTable"
        :items="users"
        :headers="headers"
        :loading="loading"
        :height="tableHeight"
        :item-class="getItemClass"
        @click:row="openUserDetail"
      >
        <!-- eslint-disable-next-line -->
        <template #item.creationDate="{ item }">
          {{ $getLocalizedDate(item.creationDate) }}
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.disabled="{ item }">
          <v-icon
            v-if="!item.enabled"
            disabled
            :data-test-id="'disabledIcon_' + item.username"
          >
            mdi-close-circle-outline
          </v-icon>
        </template>
      </v-data-table>
    </template>

    <template #detail>
      <UserDetail
        v-if="!!selectedUser"
        :preview-data="selectedUser"
        :roles="roles"
        :key="selectedUser.id"
        :style="{
          height: detailHeight + 'px',
        }"
        @close="closeDetail"
        @reload="reloadUsers"
        @deleted="closeDetail(true)"
      />
    </template>
  </MasterDetail>
</template>

<script>
import MasterDetail from "components/common/templates/MasterDetail";
import Toolbar from "components/common/templates/Toolbar";
import PaginationComponent from "components/PaginationComponent";
import mainOverviewMixin from "mixins/main-overview-mixin";
import UserDetail from "./UserDetail";
export default {
  mixins: [mainOverviewMixin],

  components: {
    MasterDetail,
    Toolbar,
    PaginationComponent,
    UserDetail,
  },

  data() {
    return {
      users: [],
      selectedUser: null,
      tableWidth: this.getPageWidth(),
      loadUserController: null,
      runningAction: false,
      filters: {},
      filterQuery: null,
      limit: 20,
      showDisabled: false,
      currentPage: 1,
      hasMorePages: false,
      roles: [],
    };
  },

  created() {
    Promise.allSettled([this.init(), this.loadRoles()]);
  },

  watch: {
    async currentPage(page) {
      //reload customers if the current page changes
      if (page < 1) page = 1;

      if (page > 0) {
        await this.$router.push({
          query: Object.assign({}, this.$route.query, {
            page,
          }),
        });

        await this.loadUsers();
      }
    },

    filterQuery: {
      handler: async function (filterQuery) {
        let query = Object.assign({}, this.$route.query);
        const queryFilter = query.filter;
        //remove filter query if the query is faulty
        if (Object.keys(filterQuery).length > 0) {
          let copy = this.$cloneObject(filterQuery);
          if (this.$isPlainObject(copy)) {
            copy = JSON.stringify(copy);
          }
          query.filter = this.$urlEncode(copy);
        } else {
          delete query.filter;
        }
        await this.$router.push({ ...this.$route, query });
        if (queryFilter !== query.filter) this.loadUsers();
      },
      deep: true,
    },

    async limit(limit) {
      await this.$router.push({
        ...this.$route,
        query: Object.assign({}, this.$route.query, { limit }),
      });

      if (this.currentPage > 1) this.currentPage = 1;
      else this.loadUsers();
    },
  },

  methods: {
    async init() {
      //initialize the component with the route parameters
      const namedRoute = this.$route.name;
      const routeQuery = this.$route.query;

      //get the query parameters and update the according variables
      let page = routeQuery.page;
      let limit = routeQuery.limit;
      let filter = routeQuery.filter;

      let pageChange = false;

      if (page) {
        page = parseInt(page, 10);
        if (Number.isNaN(page)) page = 1;
        pageChange = page !== this.currentPage;
        this.currentPage = page;
      }

      if (limit) {
        limit = parseInt(limit, 10);
        if (Number.isNaN(limit) || !this.pageLimits.includes(limit))
          limit = this.pageLimits[0];
        this.limit = limit;
      }

      if (filter) {
        try {
          filter = this.$urlDecode(filter);
          this.filterQuery = JSON.parse(filter);
        } catch (e) {
          this.filterQuery = null;
          console.warn(e);
        }
      }

      let query = {};
      if (this.currentPage) {
        query.page = this.currentPage;
      }
      if (this.limit) {
        query.limit = this.limit;
      }
      if (this.filterQuery) {
        query.filter = this.$urlEncode(JSON.stringify(this.filterQuery));
      }

      //update the URL query
      await this.$router.replace({ ...this.$route, query });

      if (
        namedRoute === "users" ||
        (namedRoute === "userDetail" && (pageChange || !this.selectedUser))
      ) {
        //load users either if the table page has changed or the user is requested directly via URL
        //if a filter is set as query parameter, do not load it here, because the customers are already reloaded when changing the filter
        await this.loadUsers();
      }

      if (namedRoute === "userDetail") {
        //show user detail of requested customer
        const userId = this.$route.params.user;

        if (!userId) {
          this.closeDetail();
        } else if (!this.selectedUser || userId !== this.selectedUser.id) {
          this.selectedUser = await this.$store.$coreApi.coreUserApi.getUser(
            this.selectedDomain,
            userId
          );
          if (!this.selectedUser) this.closeDetail();
        }
      }
    },

    async loadUsers() {
      let aborted = false;
      try {
        if (this.runningAction && this.loadUserController) {
          this.loadUserController.abort();
        }
        this.runningAction = true;
        const offset =
          this.limit * (this.currentPage > 0 ? this.currentPage - 1 : 0);
        //build request body
        let body = {
          offset,
          limit: this.limit,
        };

        //set filters
        for (let index in this.filters) {
          const filter = this.filters[index];
          const property = filter.property;
          const value = filter.value;
          body[property] = value;
        }

        if (!this.showDisabled) body.enabled = true;

        const controller = new AbortController();
        const signal = controller.signal;
        this.loadUserController = controller;

        const res = await this.$store.$coreApi.coreUserApi.searchUsers(
          this.selectedDomain,
          body,
          signal
        );

        if (res?.aborted) {
          aborted = true;
          this.loadUserController = null;
          return;
        }

        if (!res?.ok) return;
        const data = await res.json();
        this.users = data?.users ?? [];
        this.hasMorePages = data?.hasMore ?? false;
      } finally {
        if (!aborted) this.runningAction = false;
      }
    },

    async loadRoles() {
      const response = await this.$store.$coreApi.coreUserApi.getRoles(
        this.selectedDomain
      );

      this.roles = response?.roles ?? [];
    },

    async reloadUsers(userId) {
      if (!this.selectedUser.id) {
        this.$set(this.selectedUser, "id", userId);
      }
      await this.loadUsers();
    },

    async createUser() {
      await this.closeDetail();
      this.selectedUser = {};
    },

    async openUserDetail(user) {
      if (this.selectedUser?.id !== user.id) {
        await this.$router.push({
          name: "userDetail",
          params: { user: user.id },
          query: this.$route.query,
        });
        this.selectedUser = user;
      }
    },

    async closeDetail(reload) {
      await this.$router.push({
        name: "users",
        query: this.$route.query,
      });
      this.selectedUser = null;
      if (reload) this.loadUsers();
    },

    getItemClass(item) {
      let itemClass = ["user-item", item.username];
      if (item.id === this.selectedUser?.id) itemClass.push("selected");
      if (!item.enabled) itemClass.push("disabled");
      return itemClass.join(" ");
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    headers() {
      const headers = [
        { text: "Username", value: "username", sortable: false },
        { text: "Creation date", value: "creationDate", sortable: false },
        { text: "First Name", value: "firstname", sortable: false },
        { text: "Last Name", value: "lastname", sortable: false },
        { text: "E-Mail", value: "email", sortable: false },
        {
          text: "Disabled",
          value: "disabled",
          sortable: false,
          class: "disabled-column",
        },
      ];

      if (!this.showDisabled) {
        return headers.filter(({ value }) => value !== "disabled");
      }

      return headers;
    },

    possibleFilters() {
      return [
        { text: "Username", property: "username", type: "text" },
        { text: "First Name", property: "firstname", type: "text" },
        { text: "Last Name", property: "lastname", type: "text" },
        { text: "E-Mail", property: "email", type: "text" },
      ];
    },

    pageLimits() {
      return [20, 100, 200];
    },

    showMinified() {
      return (
        (this.selectedUser && this.tableWidth <= 900) ||
        this.getPageWidth() <= 900
      );
    },

    isModeCreate() {
      return !!this.selectedUser && this.selectedUser?.id === undefined;
    },
  },
};
</script>

<style scoped>
.user-table-toolbar .extended-toolbar {
  display: flex;
  height: 70px;
  align-items: center;
  padding: 0 12px 8px 12px;
}

.user-table-toolbar .extended-toolbar .user-limit-select {
  max-width: 70px;
  margin-right: 4px;
}

.user-table.v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.disabled
  > td {
  color: rgba(0, 0, 0, 0.38);
}
</style>