<template>
  <v-data-table
    hide-default-footer
    :sort-by="['id']"
    :sort-desc="[false]"
    :items-per-page="-1"
    :items="displayedRoles"
    :headers="headers"
    :loading="runningAction"
  >
    <!-- eslint-disable-next-line -->
    <template #item.enabled="{ item }">
      <v-simple-checkbox
        v-ripple="false"
        color="primary"
        :data-test-id="'role_' + item.id"
        :value="userRoles.includes(item.id)"
        :disabled="runningAction || disabled"
        @input="updateRole(item)"
      />
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    roles: {
      type: Array,
      required: true,
    },

    user: {
      type: Object,
      required: false,
      default: () => {},
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      userRoles: [],
      runningAction: false,
    };
  },

  mounted() {
    if (!this.isCreate) this.loadUserRoles();
  },

  watch: {
    "user.id": function () {
      if (!this.isCreate) this.loadUserRoles();
    },
  },

  methods: {
    async updateRole({ id, enabled }) {
      if (this.isCreate) {
        if (!enabled) {
          this.userRoles.push(id);
        } else {
          const index = this.userRoles.findIndex((role) => role === id);
          this.$delete(this.userRoles, index);
        }
        this.$emit("input", this.userRoles);
      } else {
        try {
          const userRoles = [id];
          //TODO: Implement PATCH/DELETE functionality for existing users
          if (!enabled) {
            //If role was not enabled, add it to user
            const response =
              await this.$store.$coreApi.coreUserApi.addUserRoles(
                this.selectedDomain,
                this.user.id,
                userRoles,
                {
                  successMsg:
                    `Role ` +
                    id +
                    ` <strong>added</strong> to user ` +
                    this.user.username,
                }
              );

            if (!response.ok) return;
          } else {
            //If role was enabled, remove it from user
            const response =
              await this.$store.$coreApi.coreUserApi.deleteUserRoles(
                this.selectedDomain,
                this.user.id,
                userRoles,
                {
                  successMsg:
                    `Role ` +
                    id +
                    ` <strong>removed</strong> from user ` +
                    this.user.username,
                }
              );

            if (!response.ok) return;
          }
          await this.loadUserRoles();
        } finally {
          this.runningAction = false;
        }
      }
    },

    async loadUserRoles() {
      try {
        this.runningAction = true;
        const response = await this.$store.$coreApi.coreUserApi.getUserRoles(
          this.selectedDomain,
          this.user.id
        );

        this.userRoles = response?.roles ?? [];
      } finally {
        this.runningAction = false;
      }
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    displayedRoles() {
      return this.roles.map((role) => {
        return {
          id: role,
          enabled: this.userRoles.some((userRole) => userRole === role),
        };
      });
    },

    headers() {
      return [
        { text: "Role", value: "id" },
        { text: "Enabled", value: "enabled", sortable: false },
      ];
    },

    isCreate() {
      return this.user?.id === undefined;
    },
  },
};
</script>

<style scoped>
</style>