<template>
  <v-text-field
    v-model="password"
    dense
    outlined
    hide-details="auto"
    data-test-id="passwordInput"
    clearable
    :type="type"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :append-icon="icon"
    @click:append="showPlainText = !showPlainText"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    label: {
      type: String,
      required: false,
      default: "Password",
    },

    rules: {
      type: Array,
      required: false,
      validator: (rules) => rules.every((rule) => typeof rule === "function"),
    },
  },

  data() {
    return {
      password: this.value,
      showPlainText: false,
    };
  },

  watch: {
    password(password) {
      this.$emit("input", password);
    },

    value(value) {
      this.password = value;
    },
  },

  computed: {
    type() {
      return this.showPlainText ? "text" : "password";
    },

    icon() {
      return this.showPlainText ? "mdi-eye-off-outline" : "mdi-eye-outline";
    },
  },
};
</script>

<style scoped>
</style>