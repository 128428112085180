var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-permission-overview"},[_c('DataTable',{staticClass:"permission-table",attrs:{"title":"Permissions","items":_vm.directPermissions,"headers":_vm.permissionHeaders,"loading":_vm.loadingDirectPermissions,"limit":_vm.limit},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var loading = ref.loading;
return [_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","data-test-id":"addPermissionBtn","disabled":_vm.disabled || loading},on:{"click":function($event){_vm.addPermission = true}}},[_vm._v(" Add Permission ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var loading = ref.loading;
return [_c('v-btn',{attrs:{"small":"","icon":"","color":"red","disabled":_vm.disabled || loading,"data-test-id":'revokePermissionBtn_' + item.resourceDomain},on:{"click":function($event){return _vm.revokePermission(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}])}),_c('DataTable',{staticClass:"inherited-permission-table",attrs:{"title":"Permissions from groups","items":_vm.groupPermissions,"headers":_vm.inheritedPermissionHeaders,"loading":_vm.loadingGroupPermissions,"limit":_vm.limit},scopedSlots:_vm._u([{key:"item.group",fn:function(ref){
var item = ref.item;
var loading = ref.loading;
return [_c('a',{attrs:{"href":_vm.getGroupLink(item.group.domainId, item.group.id),"data-test-id":'permissionAncestorName_' + item.group.name},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openGroupDetail(item.group.domainId, item.group.id)}}},[_vm._v(" "+_vm._s(item.group.name)+" ")])]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.addPermission),callback:function ($$v) {_vm.addPermission=$$v},expression:"addPermission"}},[_c('Permission',{attrs:{"entity-id":_vm.userId},on:{"close":_vm.closeDetail}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }